var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"member_wrap"},[_c('div',{staticClass:"member_top"},[_vm._v(" 램 일반회원가입"),_c('router-link',{attrs:{"to":"/member/terms"}},[_c('img',{attrs:{"src":require("@/assets/images/sub/back_ico.png"),"alt":""}})])],1),_c('ValidationObserver',{ref:"obServer"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.authPhoneConfirm.apply(null, arguments)}}},[_c('div',{staticClass:"member_box"},[_c('div',{staticClass:"mem_input"},[_c('div',{staticClass:"box"},[_c('h3',[_vm._v("휴대전화")]),_c('ValidationProvider',{ref:"phone",attrs:{"rules":{
                required: true,
                cellphone: true,
              },"name":"휴대폰번호"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"input",style:({ position: 'relative' })},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cellphoneCk),expression:"cellphoneCk"}],attrs:{"type":"text","name":"cellphone","placeholder":"휴대폰번호 입력","maxlength":"11"},domProps:{"value":(_vm.cellphoneCk)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getAuthNumber.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.cellphoneCk=$event.target.value}}}),(errors[0])?_c('p',{staticClass:"error-txt"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),(_vm.isAuthNumber)?_c('div',{staticClass:"timer"},[_c('span',{ref:"timer_min"},[_vm._v("2")]),_c('span',[_vm._v(":")]),_c('span',{ref:"timer_sec"},[_vm._v("59")])]):_vm._e()])]}}])}),_c('div',{staticClass:"btn"},[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){$event.preventDefault();return _vm.getAuthNumber.apply(null, arguments)}}},[_vm._v("인증번호신청")])],1)],1),_c('div',{staticClass:"box"},[_c('h3',[_vm._v("인증번호")]),_c('ValidationProvider',{ref:"authNum",attrs:{"rules":{
                required: true,
                digits: 6,
              },"name":"인증번호"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"input type_check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.authNumberCk),expression:"authNumberCk"}],attrs:{"type":"text","name":"auth_number","placeholder":"인증번호 입력","maxlength":"6"},domProps:{"value":(_vm.authNumberCk)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.authPhoneConfirm.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.authNumberCk=$event.target.value}}})]),(errors[0])?_c('p',{staticClass:"error-txt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"mem_btn"},[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.authPhoneConfirm.apply(null, arguments)}}},[_vm._v("다음으로")])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }