<template>
  <div class="member_wrap">
    <div class="member_top">
      램 일반회원가입<router-link to="/member/terms"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <ValidationObserver ref="obServer">
      <form @submit.prevent="authPhoneConfirm">
        <div class="member_box">
          <div class="mem_input">
            <div class="box">
              <h3>휴대전화</h3>
              <ValidationProvider
                ref="phone"
                :rules="{
                  required: true,
                  cellphone: true,
                }"
                v-slot="{ errors }"
                name="휴대폰번호"
              >
                <div class="input" :style="{ position: 'relative' }">
                  <input
                    type="text"
                    name="cellphone"
                    v-model="cellphoneCk"
                    placeholder="휴대폰번호 입력"
                    maxlength="11"
                    @keypress.enter="getAuthNumber"
                  />
                  <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                  <div class="timer" v-if="isAuthNumber">
                    <span ref="timer_min">2</span>
                    <span>:</span>
                    <span ref="timer_sec">59</span>
                  </div>
                </div>
              </ValidationProvider>

              <div class="btn">
                <router-link to="" @click.native.prevent="getAuthNumber"
                  >인증번호신청</router-link
                >
              </div>
            </div>
            <div class="box">
              <h3>인증번호</h3>
              <ValidationProvider
                ref="authNum"
                :rules="{
                  required: true,
                  digits: 6,
                }"
                v-slot="{ errors }"
                name="인증번호"
              >
                <div class="input type_check">
                  <input
                    type="text"
                    name="auth_number"
                    v-model="authNumberCk"
                    placeholder="인증번호 입력"
                    maxlength="6"
                    @keypress.enter="authPhoneConfirm"
                  />
                </div>
                <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
          </div>
          <div class="mem_btn">
            <router-link to="" @click.native="authPhoneConfirm"
              >다음으로</router-link
            >
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      isTimer: false,
      timer: "",
      cellphone: null,
      authNumber: null,
    };
  },
  computed: {
    ...mapState("member", [
      "msg",
      "isCheckPhone",
      "old_cellphone",
      "finance",
      "mailing",
      "sms",
      "service",
      "privacy",
      "over14",
      "tryCnt",
      "limitCnt",
    ]),
    cellphoneCk: {
      get() {
        return this.cellphone;
      },
      set(cellphone) {
        this.cellphone = cellphone.replace(/[^0-9]/g, "");
      },
    },
    authNumberCk: {
      get() {
        return this.authNumber;
      },
      set(authNumber) {
        this.authNumber = authNumber.replace(/[^0-9]/g, "");
      },
    },
    isAuthNumber: {
      get() {
        return this.$store.state.member.isAuthNumber;
      },
      set(isAuthNumber) {
        this.$store.commit("member/updateUserInfo", {
          isAuthNumber,
        });
      },
    },
  },
  created() {
    //초기화
    this.$store.commit("member/updateCellphoneReset");
    if (!this.service || !this.finance || !this.privacy || !this.over14) {
      this.$toast.default("약관에 동의 해주세요.");
      this.$router.push("/member/terms");
      return false;
    }
  },
  methods: {
    async getAuthNumber() {
      await this.$refs.phone.validate();
      const phoenReg = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/;
      if (!phoenReg.test(this.cellphone)) {
        return false;
      }
      if (this.isAuthNumber) {
        if (this.old_cellphone === this.cellphoneCk) {
          this.$toast.default(
            `동일한 번호로 이미 인증번호를 발송 했습니다.<br/>
          인증번호 요청시도(${this.tryCnt}/${this.limitCnt})`
          );
          return false;
        }
      }
      this.isAuthNumber = false;
      clearInterval(this.timer);
      this.isTimer = false;

      await this.$store.dispatch("member/getAuthNumber", {
        cellphone: this.cellphone,
      });
      if (this.isAuthNumber) {
        if (this.isTimer === false) {
          this.startTimer();
        }
        this.$toast.default(this.msg);
      } else {
        this.$toast.default(this.msg);
      }
    },
    async authPhoneConfirm() {
      await this.$refs.authNum.validate();
      if (this.isAuthNumber === false) {
        this.$toast.default("휴대폰 번호 인증을 해주세요.");
        return false;
      }
      await this.$store.dispatch("member/authPhoneConfirm", {
        cellphone: this.cellphone && this.cellphone.trim(),
        authNumber: this.authNumber && this.authNumber.trim(),
      });
      if (this.isCheckPhone) {
        this.$toast.default(this.msg);
        this.$router.push("/member/member_register");
      } else {
        this.$toast.default(this.msg);
      }
    },
    startTimer() {
      this.isTimer = true;
      const minEl = this.$refs.timer_min;
      const secEl = this.$refs.timer_sec;
      let sec = 59;
      let min = 2;
      let sec_str = "";
      this.timer = setInterval(() => {
        if (sec == 0) {
          sec = 59;
          if (min != 0) {
            min = min - 1;
          }
        } else {
          sec = sec - 1;
          sec_str = sec.toString();
          if (sec < 10) {
            sec_str = `0${sec.toString()}`;
          }
        }
        if (min == 0 && sec == 0) {
          clearInterval(this.timer);
          this.isAuthNumber = false;
        }
        minEl.textContent = min;
        secEl.textContent = sec_str;
      }, 1000);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.timer {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  color: $info-txt;
  font-size: 14px;
}
#timer_min {
  float: none;
}
#timer_sec {
  float: none;
}
.mem_btn {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  a {
    height: 50px !important;
    line-height: 50px !important;
  }
}
input[name="auth_number"] {
  width: 100% !important;
}
</style>
