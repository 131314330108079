<template>
  <AuthPhone />
</template>

<script>
import AuthPhone from "@/components/member/AuthPhone";
export default {
  metaInfo() {
    return {
      title: `${process.env.VUE_APP_APP_NAME}`,
      meta: [
        {
          vmid: "title",
          name: "title",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "author",
          name: "author",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "description",
          name: "description",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `${process.env.VUE_APP_API_URL}${this.$route.fullPath}`,
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        { vmid: "og:type", property: "og:type", content: "website" },
        { vmid: "og:image", property: "og:image", content: "" },
      ],
    };
  },
  components: {
    AuthPhone,
  },
  created() {
    this.$store.dispatch("common/setFooter", {
      viewFooter: false,
    });
  },
};
</script>

<style></style>
